import React, { useContext, useEffect } from "react";
import cardPackageStyle from "./package.module.css";
import { MdCall } from "react-icons/md";
import { Spinner } from "baseui/spinner";
import { Badge, COLOR, SHAPE } from "baseui/badge";
import { ApplicationContext } from "../../../../context/ApplicationContextProvider";
import useGetDuPartnerFare from "../../../../hooks/useGetDuPartnerFare";
import { packageDetailPagePartner } from "../../../../utils/EventObject";
import cleverTapPartner from "../../../../cleverTapConfig";
import {
  DuLocationGreen,
  DuLocationRed,
} from "../../../../utils/ImportAssets/ImportSvg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import carouselDuDaily from "./carousel.module.css";

const TrackPackagePartner = ({ partnerBookData }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      partialVisibilityGutter: 40,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 25,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 25,
    },
  };

  const instructionsStyle = {
    borderRadius: " 0.3125rem",
    border: "1px solid #e6e6e8",
    background: "#fff",
    marginTop: "1rem",
    padding: "0.75rem",
  };
  const { driverData } = useContext(ApplicationContext);
  const fareDataDU = {
    driver_id: driverData.driver_id,
    imei_number: driverData.imei_number,
    booking_id: partnerBookData.booking_id,
  };
  const { fareDUPartner } = useGetDuPartnerFare(fareDataDU);
  useEffect(() => {
    if (fareDUPartner.length !== 0) {
      console.log(partnerBookData);
      packageDetailPagePartner.order_id = partnerBookData.booking_id;
      packageDetailPagePartner["package status"] = partnerBookData.status;
      packageDetailPagePartner["cta shown"] = partnerBookData.button_text;
      packageDetailPagePartner["widget source"] = "active";
      packageDetailPagePartner["Estimated usage"] =
        partnerBookData.details.find(
          (elem) => elem.key === "Daily Package"
        ).value;
      packageDetailPagePartner["pickup time"] = partnerBookData.details.find(
        (elem) => elem.key === "Daily Pickup Time"
      ).value;
      packageDetailPagePartner.upcoming =
        partnerBookData.booking_days?.upcoming_bookings
          ?.map((elem) => elem.date)
          .join(",");
      packageDetailPagePartner.completed =
        partnerBookData.booking_days?.completed_bookings
          ?.map((elem) => elem.date)
          .join(",");
      packageDetailPagePartner.cancelled =
        partnerBookData.booking_days?.cancelled_bookings
          ?.map((elem) => elem.date)
          .join(",");
      // packageDetailPagePartner["start date"] =
      //   partnerBookData?.booking_days?.upcoming_bookings[0]?.date;
      if (packageDetailPagePartner.earnings > 0) {
        packageDetailPagePartner.earnings = fareDUPartner.breakup.find(
          (elem) => elem.key === "Expected Total Earnings"
        ).value;
      }

      cleverTapPartner.event.push(
        "Package detail page opened",
        packageDetailPagePartner
      );
    }
    // eslint-disable-next-line
  }, [fareDUPartner]);

  return (
    <div className={cardPackageStyle.packageDetailsContainer}>
      <section>
        <span>Package Details #{partnerBookData.booking_id}</span>
        {partnerBookData.ACTION === "RESERVE" ? null : (
          <Badge
            content={partnerBookData.status}
            shape={SHAPE.pill}
            color={COLOR.positive}
          />
        )}
      </section>
      <div className={cardPackageStyle.mainPartnerDetails}>
        <div className={cardPackageStyle.customerDetails}>
          <DuLocationGreen style={{ width: "10%" }} />
          <span>{partnerBookData.bottom_text}</span>
        </div>
        <>
          {partnerBookData.drop_address && (
            <div
              className={cardPackageStyle.customerDetails}
              style={{ marginTop: "0.5rem" }}
            >
              <DuLocationRed style={{ width: "10%" }} />
              <span>{partnerBookData.drop_address}</span>
            </div>
          )}
        </>
        <div className={cardPackageStyle.activeCardDetailsMain}>
          <div className={cardPackageStyle.cardLayoutDU}>
            {!partnerBookData.customer_number === true ? null : (
              <>
                <div>Customer</div>
                <a
                  href={`tel:${partnerBookData.customer_number}`}
                  className={cardPackageStyle.customerCall}
                >
                  <span>{partnerBookData.customer}</span>
                  <MdCall
                    style={{
                      background: "#009D61",
                      color: "#fff",
                      borderRadius: "50%",
                      padding: "5px",
                    }}
                    size={24}
                  />
                </a>
              </>
            )}
          </div>
          <>
            {partnerBookData.details.map((elem, index) => {
              return (
                <div className={cardPackageStyle.cardLayoutDU} key={index}>
                  <div>{elem.key}</div>
                  <section>{elem.value}</section>
                </div>
              );
            })}
          </>
        </div>

        <div className={cardPackageStyle.duBookDetailsBlock}>
          <div style={{ width: "90%" }}>
            {!partnerBookData.booking_days.completed_bookings ===
            true ? null : (
              <div>
                <span style={{ color: "#009D61" }}>Completed: </span>
                <span>
                  {partnerBookData.booking_days.completed_bookings.map(
                    (elem, idx) => (
                      <span key={idx}>
                        {elem.date}
                        {idx !==
                          partnerBookData.booking_days.completed_bookings
                            .length -
                            1 && ","}
                      </span>
                    )
                  )}
                </span>
              </div>
            )}
            {!partnerBookData.booking_days.upcoming_bookings === true ? null : (
              <div>
                <span style={{ color: "#3F69FF" }}>Upcoming: </span>
                <span>
                  {partnerBookData.booking_days.upcoming_bookings.map(
                    (elem, idx) => (
                      <span key={idx}>
                        {elem.date}
                        {idx !==
                          partnerBookData.booking_days.upcoming_bookings
                            .length -
                            1 && ","}
                      </span>
                    )
                  )}
                </span>
              </div>
            )}
            {!partnerBookData.booking_days.cancelled_bookings ===
            true ? null : (
              <div>
                <span style={{ color: "#F15355" }}>Cancelled: </span>
                <span>
                  {partnerBookData.booking_days.cancelled_bookings.map(
                    (elem, idx) => (
                      <span key={idx}>
                        {elem.date}
                        {idx !==
                          partnerBookData.booking_days.cancelled_bookings
                            .length -
                            1 && ","}
                      </span>
                    )
                  )}
                </span>
              </div>
            )}
          </div>
        </div>

        <>
          {partnerBookData.instructions ? (
            <div style={instructionsStyle}>
              <strong>Instruction</strong>
              <div>{partnerBookData.instructions}</div>
            </div>
          ) : null}
        </>

        <>
          {fareDUPartner.length === 0 ? (
            <Spinner $color="green" />
          ) : (
            <>
              {fareDUPartner.version == "v2" ? (
                <>
                  <strong className="p-3 border-b border-gray-300 flex items-center justify-between">
                    Earnings Breakup
                  </strong>
                  <Carousel responsive={responsive} partialVisible={true}>
                    {fareDUPartner.breakup.map((ele, i) => {
                      const { heading, status, colour, details } = ele;
                      return (
                        <>
                          <div
                            className={`${carouselDuDaily.earningHistoryContainer}`}
                          >
                            <div
                              className={`${carouselDuDaily.earningHeadingStatus} ${carouselDuDaily.textMargin} ${carouselDuDaily.givingPadding}`}
                            >
                              <p>{heading}</p>
                              <p
                                style={{
                                  color: `${colour}`,
                                  backgroundColor: "white",
                                  fontWeight: "600",
                                }}
                              >
                                {status}
                              </p>
                            </div>
                            <hr
                              classname={`${carouselDuDaily.horizontalLine}`}
                            />
                            {details.map(({ label, value, sub_title }) => (
                              <>
                                <div
                                  className={`${carouselDuDaily.earningCollected} ${carouselDuDaily.textMargin}`}
                                >
                                  <p>{label}</p>
                                  <p>{value}</p>
                                </div>
                                <div
                                  className={
                                    sub_title === "" ? { display: "none" } : carouselDuDaily.carouselSubheadingPara
                                  }
                                >
                                  <p
                                    // className={`carousel_earningCollected__g32av carousel_textMargin__eEq13`}
                                    style={
                                      sub_title === ""
                                        ? { display: "none" }
                                        : { color: "#6b6b76", fontSize: "12px" ,paddingBottom : "12px" }
                                    }
                                  >
                                    {sub_title}
                                  </p>
                                </div>
                              </>
                            ))}

                            {/* <div
                                className={`${carouselDuDaily.earningCredited} ${carouselDuDaily.textMargin}`}
                              >
                                <p>{details}</p>
                                <p>{credited}</p>
                              </div>
                              <div
                                className={`${carouselDuDaily.earningGurantee} ${carouselDuDaily.textMargin}`}
                              >
                                <p>
                                  Gurantee Settlement{" "}
                                  <span style={{ color: "rgba(0,0,0,0.4)" }}>
                                    <IoIosInformationCircleOutline className={carouselDuDaily.circleInfo} />
                                  </span>
                                </p>
                                <p>{guarantee}</p>
                              </div> */}
                          </div>
                        </>
                      );
                    })}
                  </Carousel>
                </>
              ) : (
                <div className="rounded-md border border-gray-300 flex w-full justify-start flex-col mt-8">
                  <strong className="p-3 border-b border-gray-300 flex items-center justify-between">
                    Earnings Breakup
                  </strong>
                  <div className="p-3 flex flex-col gap-3">
                    <div>
                      <>
                        {fareDUPartner.breakup
                          .slice(1, fareDUPartner.breakup.length - 1)
                          .map((fareData, idx) => (
                            <div
                              key={`fareData-${idx}`} // Unique key prop
                              style={{
                                color: fareData.colour,
                              }}
                              className="flex items-center justify-between p-2"
                            >
                              <span>{fareData.key}</span>
                              <span>₹{fareData.value}</span>
                            </div>
                          ))}
                      </>
                      <hr />
                      <>
                        {fareDUPartner.breakup
                          .slice(
                            fareDUPartner.breakup.length - 1,
                            fareDUPartner.breakup.length
                          )
                          .map((fareData, idx) => (
                            <div
                              key={`fareData-${idx}`} // Unique key prop
                              style={{
                                color: "#000",
                                fontWeight: 500,
                              }}
                              className="flex items-center justify-between p-2"
                            >
                              <span>{fareData.key}</span>
                              <span>₹{fareData.value}</span>
                            </div>
                          ))}
                      </>
                      <hr style={{ opacity: "0.2" }} />
                      <>
                        {fareDUPartner.breakup
                          .slice(0, 1)
                          .map((fareData, idx) => (
                            <React.Fragment key={`fareData-${idx}`}>
                              {!fareData.value === true ? null : (
                                <div
                                  style={{
                                    color: "#000",
                                    fontWeight: 500,
                                  }}
                                  className="flex items-center justify-between p-2"
                                >
                                  <span>{fareData.key}</span>
                                  <span>₹{fareData.value}</span>
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                      </>
                    </div>

                    <hr />
                    {Object.keys(fareDUPartner.payment_collection).length ===
                    0 ? null : (
                      <>
                        <div>
                          <div className="flex items-center justify-between p-2">
                            <strong>Payment Collection</strong>
                          </div>

                          <div className="flex items-center justify-between p-2">
                            <span>
                              {fareDUPartner.payment_collection.label}
                            </span>
                            {!fareDUPartner.payment_collection.amount ===
                            true ? null : (
                              <strong>
                                ₹{fareDUPartner.payment_collection.amount}
                              </strong>
                            )}
                          </div>
                        </div>
                        <hr />
                      </>
                    )}

                    <div className={cardPackageStyle.fareText}>
                      <ul className="text-gray-600 text-xs list-disc">
                        <li>
                          Actual earnings of trips & package will be
                          recalculated after each trip end
                        </li>
                        <li>Payment collection on last date of booking only</li>
                        <li>
                          For individual trip breakup, go to History check
                          details for a booking under DU Daily Package Order
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default TrackPackagePartner;
