import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Input } from "baseui/input";
import axios from "axios";
import { ApplicationContext } from "../../../../context/ApplicationContextProvider";
import { useLocation, useNavigate } from "react-router-dom";
import "./insurance.css";
import BenefitsDetails from "./BenefitsDetails";
import InsurancePdf from "./InsurancePdf"

const InsurancePage = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const inputRef = useRef(null);
  const { insurance, setInvoiceData } = useContext(ApplicationContext);
  const [successScreen, setSuccessScreen] = useState(false);
  const [insuranceData, setInsuranceData] = useState({
    name: "",
    mobile: "",
    imei_number: insurance.imei_number,
    driver_id: insurance.driver_id,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (insurance.lead_status === "1") {
      navigate("/ins-form-fill");
    } else if (insurance.lead_status === "3") {
      console.log("---------data is coming ----------");
      const invoice = {
        insurance_id: params.get("insurance_id"),
        validity: params.get("validity"),
      };
      setInvoiceData((prev) => ({
        ...prev,
        ...invoice,
      }));
      console.log("invoice => ", invoice);
      navigate("/ins-invoice");
    }
  }, [insurance.lead_status, navigate]);

  useEffect(() => {
    if (insurance.name) {
      setInsuranceData((prevData) => ({
        ...prevData,
        name: insurance.name,
      }));
    }
    if (insurance.mobile) {
      setInsuranceData((prevData) => ({
        ...prevData,
        mobile: insurance.mobile,
      }));
    }
  }, [insurance.name, insurance.mobile]);

  useEffect(() => {
    const adjustViewportHeight = () => {
      const viewportHeight = window.innerHeight;
      document.documentElement.style.setProperty(
        "--vh",
        `${viewportHeight * 0.01}px`
      );
    };

    window.addEventListener("resize", adjustViewportHeight);
    adjustViewportHeight();

    return () => window.removeEventListener("resize", adjustViewportHeight);
  }, []);

  const handleChange = (elementId) => {
    const element = document.getElementById(elementId);

    if (element || (inputRef.current && inputRef.current.input)) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  };
  const handleContactChange = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, "");
    if (value.length <= 10 && (value.length == 0 || value[0] !== "0")) {
      setInsuranceData((prevData) => ({
        ...prevData,
        mobile: value,
      }));
    }
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z ]*$/.test(value) || value === "") {
      setInsuranceData((prevData) => ({
        ...prevData,
        name: value,
      }));
    }
  };

  // form handling start here

  const handleInsuranceDetails = (e) => {
    e.preventDefault();

    if (insuranceData.mobile.length !== 10) {
      toast.error("Mobile number must be exactly 10 digits", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else {
      insuranceData.imei_number = insurance.imei_number;
      insuranceData.driver_id = insurance.driver_id;
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_WEBSITE_URL}/web/insurance-lead/`,
        headers: {
          "Content-Type": "application/json", // Set appropriate headers
          // Add any other headers as needed
        },
        data: insuranceData,
      };
      axios(config)
        .then((response) => {
          setSuccessScreen(true);
          if (response.data.status === "success") {
            setSuccessScreen(true);
            toast.success("Thank you!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            navigate("/ins-form-fill");
          } else if (response.data.status === "error") {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });      // navigate("/ins-form-fill")
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        });
    }
  };


  return (
    <>
      <div className="bg-white text-gray-900 w-[90%] m-auto">
        <div className="max-w-7xl mx-auto p-2">
          {/* ======Heading Section========= */}

          <div className="mb-4">
            <div>
              {" "}
              {/*className={styles.removeInMobile} */}
              {/* <figure>
                <img
                  src="https://www.driveu.in/car-care/wp-content/uploads/2023/11/400x151-DriveU-Car-Care-Logo.png"
                  alt="DriveU Logo"
                  className="w-40 h-22 ml-[-6px]"
                />
              </figure> */}
            </div>
            <p className="text-[16px] sm:text-[16px] md:text-[20px] mb-1">
              Get exclusive health protection plan
            </p>
            <p className= "text-sm mb-4">powered by Reliance General Insurance </p>

            <button
              onClick={() => handleChange("inputField")}
              className=" bg-primaryGreen p-2 px-3 text-xs text-white rounded-3xl"
            >
              Get In touch with DriveU Team
            </button>
          </div>

          {/*===== Image Section===== */}

          <hr className="border-1 border-black" />

          {/* =====Benefits Section==== */}

          <div className="mb-4 mt-4">
            <h2 className="text-xl font-bold mb-2">
              <strong>How does it work?</strong>
            </h2>

            {/* ====List of benefits===== */}

            <div className="flex flex-wrap mt-2 flex-col items-start">
              {/* Repeat this block for each feature */}
              <div className="flex justify-center items-center">
                <div className="w-6 h-6 sm:w-6 sm:h-6 md:h-8 md:w-8  flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    width="20"
                    height="20"
                  >
                    <path
                      fill="currentColor"
                      d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"
                    ></path>
                  </svg>

                  {/* <svg height="30" width="30" xmlns="http://www.w3.org/2000/svg">
                    <circle r="4" cx="5" cy="5" fill="black" />
                  </svg> */}
                </div>

                <p className="text-[14px] text-left ml-5">
                  Submit your details in driveU team
                </p>
              </div>

              <div className="flex justify-center items-center">
                <div className="w-6 h-6 sm:w-6 sm:h-6 md:h-8 md:w-8 flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    width="20"
                    height="20"
                  >
                    <path
                      fill="currentColor"
                      d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"
                    ></path>
                  </svg>
                </div>

                <p className="text-[14px] text-left ml-5">
                  DriveU team will call you for confirm details 
                </p>
              </div>

              <div
                className="flex justify-center items-start"
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <div className="w-6 h-6 sm:w-6 sm:h-6 md:h-8 md:w-8 flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    width="20"
                    height="20"
                  >
                    <path
                      fill="currentColor"
                      d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"
                    ></path>
                  </svg>
                </div>

                <p className="text-[14px] text-left ml-5">
                  Cost : 210 + 18% GST to 30days
                </p>
              </div>
            </div>
            {/* 
          <Button
            variant="contained"
            color="success"
            sx={{
              marginTop: "1rem",
              backgroundColor: "black",
              "&:hover": { backgroundColor: "darkgray" },
            }}
            onClick={() => smoothScrollToElement("target-element")}
          >
            Explore Ads
          </Button> */}
          </div>

          <BenefitsDetails />

          {/*==== Trust Section===== */}

          {/* <div className="mb-4 text-left">
            <iframe
              src="https://driveu-zappa-static.s3.ap-south-1.amazonaws.com/Group_Mediclaim_Policy_Wording.pdf"
              style={{ width: "100%", height: "600px", border: "none" }}
              title="Policy Wordings"
            >
              This browser does not support PDFs. Please download the PDF to
              view it:
              <a href="https://driveu-zappa-static.s3.ap-south-1.amazonaws.com/Group_Mediclaim_Policy_Wording.pdf">
                Download PDF
              </a>
            </iframe>
          </div> */}

        <InsurancePdf/>  


          <hr className="border-1 border-black" />

          {/* =======Contact Form Section ===*/}

          <div className="mb-10">
            <h2 className="text-md font-bold mb-4 mt-4">
              <strong>Get in touch with DriveU for Insurance?</strong>
            </h2>

            {!successScreen && (
              <div>
                {/* <ToastContainer
                  position="bottom-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                /> */}

                <form
                  onSubmit={handleInsuranceDetails}
                  className={`${successScreen ? "hidden" : "block"}`}
                >
                  <Input
                    inputRef={(ref) => (inputRef.current = ref)}
                    // glowing effect
                    overrides={{
                      Root: {
                        style: (props) => {
                          const {
                            $disabled,
                            $error,
                            $isFocused,
                            $theme: { colors, sizing },
                          } = props;
                          const border = $disabled
                            ? colors.borderTransparent
                            : $error
                            ? colors.borderNegative
                            : $isFocused
                            ? "darkseagreen"
                            : colors.borderOpaque;
                          return {
                            borderLeftColor: border,
                            borderRightColor: border,
                            borderTopColor: border,
                            borderBottomColor: border,
                            boxShadow: `0 0 ${sizing.scale100} ${
                              $disabled
                                ? "transparent"
                                : $error
                                ? colors.shadowError
                                : $isFocused
                                ? "lightseagreen"
                                : "transparent"
                            }`,
                          };
                        },
                        props: {
                          className: "mb-4",
                        },
                      },
                    }}
                    value={insuranceData.name}
                    placeholder="Enter Your Name"
                    onChange={handleNameChange}
                  />

                  <Input
                    type="number"
                    overrides={{
                      Root: {
                        style: (props) => {
                          const {
                            $disabled,
                            $error,
                            $isFocused,
                            $theme: { colors, sizing },
                          } = props;
                          const border = $disabled
                            ? colors.borderTransparent
                            : $error
                            ? colors.borderNegative
                            : $isFocused
                            ? "darkseagreen"
                            : colors.borderOpaque;
                          return {
                            borderLeftColor: border,
                            borderRightColor: border,
                            borderTopColor: border,
                            borderBottomColor: border,
                            boxShadow: `0 0 ${sizing.scale100} ${
                              $disabled
                                ? "transparent"
                                : $error
                                ? colors.shadowError
                                : $isFocused
                                ? "lightseagreen"
                                : "transparent"
                            }`,
                          };
                        },
                        props: {
                          className: "mb-4",
                        },
                      },
                    }}
                    value={insuranceData.mobile}
                    onChange={handleContactChange}
                    placeholder="Enter Your Phone Number"
                  />
                  {/* <Button shape={SHAPE.pill} colors  = {{backgroundColor: '#006D35',color: 'white' }}>Submit</Button> */}
                  <button
                    className=" bg-primaryGreen px-4 py-2  text-white rounded-3xl"
                    id="inputField"
                  >
                    Submit
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(InsurancePage);
